.pricing-banner-section {
    background: url("../../../assets/images/pricing-banner.png");
    width: 100%;
    background-repeat: repeat;
    background-size: cover;

    .pricing-banner-alignment {
        padding: 35px 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 250px;

        @media (max-width: 768px) {
            gap: 50px;
            padding: 35px;
        }

        @media (max-width: 576px) {
            flex-direction: column;
            gap: 40px;
            padding: 35px;
        }

        .pricing-left {
            max-width: 536px;

            @media (max-width: 576px) {
                width: auto;
                max-width: unset;
            }

            h1 {
                font-size: 23px;
                font-weight: 500;
                line-height: 32px;
                text-align: left;
                color: #000000;

                @media (max-width: 576px) {
                    text-align: center;
                }
            }

            .enquiry-button {
                padding: 38px 0 0 0;

                @media (max-width: 576px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                button {
                    padding: 7px;
                    background: #00edac;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 28px;
                    text-align: center;
                    border: none;
                    color: #fff;
                    border-radius: 16px;
                    width: 180px;
                    cursor: pointer;
                }
            }
        }

        .pricing-right {
            max-width: 285px;
        }
    }
}