.customizedPricing-section {
    padding: 60px 0 0 0;
    max-width: 1076px;
    margin: 0 auto;

    @media (max-width: 576px) {
        max-width: 476px;
    }

    h3 {
        font-size: 26px;
        font-weight: 500;
        line-height: 36px;
        color: #0000004d;

        @media (max-width: 768px) {
            text-align: center;
        }

        @media (max-width: 576px) {
            text-align: center;
        }
    }

    .customizedPricing-details {
        .customizedPricing-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 37px;
        }
    }

    .contact-us-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-top: 60px;

        button {
            width: 180px;
            background: #00edac;
            border: none;
            border-radius: 16px;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            padding: 10px;
            color: #fff;
            cursor: pointer;
        }
    }

    .pricingStructure-all-details {
        padding: 30px 0 0 0;
        max-width: 1076px;
        margin: 0 auto;


        .pricing-box {
            border: 1px solid #00000080;
            border-radius: 20px;
            padding: 22px 34px;

            .pricicng-top {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    font-size: 40px;
                    font-weight: 500;
                    line-height: 56px;
                    color: #000000;
                }

                .rightSide {
                    p {
                        font-size: 50px;
                        font-weight: 600;
                        line-height: 70px;
                        color: #000000;

                        span {
                            font-size: 20.15px;
                            font-weight: 500;
                            line-height: 28.38px;
                            color: #0000004d;
                        }
                    }
                }
            }

            .pricing-bottom {
                padding: 64px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #00000080;
                    max-width: 324px;
                }

                button {
                    width: 180px;
                    background: #00edac;
                    border: none;
                    border-radius: 16px;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 28px;
                    padding: 7px;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }

}

.custom-modal-wrapper {
    width: 100%;

    label {
        font-family: Anek Tamil;
        font-size: 18px;
        font-weight: 400;
        line-height: 22.53px;
        text-align: left;
        color: #000000;
    }

    button {
        width: 130px;
        background: #00edac;
        border: none;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        padding: 6px;
        color: #000;
        cursor: pointer;
        text-transform: capitalize;

        &:hover {
            background: #00edac;
        }
    }
}