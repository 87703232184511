.served-to-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .our-services-title {
        font-size: 40px;
        font-weight: 500;
        line-height: 56px;
        text-align: center;
        margin-top: 60px;
        color: #000;
    }

    .served-to-card-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 50px;

        .served-to-card-content {
            display: flex;
            align-items: center;
            padding: 24px 18px;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            border: 1px solid;
            border-radius: 24px;
            box-shadow: 0 10px 2px -2px #00edac;
            width: 227px;

            .card-title {
                font-size: 23px;
                font-weight: 500;
                line-height: 32px;
            }
        }
    }
}