.book-call-banner-main-wrapper {
    background-color: #00EDAC;
    padding: 40px 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 576px) {
        flex-direction: column;
        gap: 20px;
        padding: 20px;
    }

    .icon-text-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        img {
            @media (max-width: 576px) {
                width: 50px;
            }
        }

        .book-text {
            font-family: Anek Tamil;
            font-size: 40px;
            font-weight: 500;
            line-height: 56.32px;
            text-align: center;

            @media (max-width: 576px) {
                font-size: 20px;
                line-height: 36.32px;
                text-wrap: nowrap;
            }
        }
    }

    .book-call-btm-wrapper {
        button {
            font-family: Anek Tamil;
            font-size: 20.15px;
            font-weight: 600;
            line-height: 28.38px;
            text-align: center;
            background-color: #fff;
            border: none;
            border-radius: 15.5px;
            padding: 10px 25px;
            cursor: pointer;

            @media (max-width: 576px) {
                font-size: 16px;
                line-height: 24px;
                padding: 10px 20px;
            }
        }
    }
}