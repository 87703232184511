.features-cash-section {
    padding: 145px 0 0 0;

    .features-cash-heading {
        text-align: center;

        h2 {
            font-size: 48px;
            font-weight: 600;
            line-height: 68px;
        }
    }

    .features-cash-details-alignment {
        max-width: 990px;
        margin: 0 auto;
        padding: 50px 0 0 0;

        .features-cash-tab-details {
            padding: 0 42px;

            @media (max-width: 576px) {
                display: none;
            }

            .row-alignment {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 60px;
                padding: 0 40px;
                margin: 0 0 18px 0;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background: #979797;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .tab-alignment {
                    background: #00f4ad;
                    padding: 10px 25px;
                    border-radius: 38px;
                    position: relative;

                    p {
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 34px;
                        color: #000000;
                    }
                }

                &:nth-child(2) {
                    justify-content: flex-start;
                }

                &:nth-child(3) {
                    padding: 0;
                    justify-content: flex-end;
                    gap: 36px;
                }

                &:nth-child(4) {
                    gap: 30px;
                    justify-content: flex-start;
                }

                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}