.hiringVirtualCfoSection {
    padding: 60px 0 0 0;

    .hiringVirtualCfoHeading {
        text-align: center;

        @media (max-width: 768px) {
            margin-top: 40px;
        }

        @media (max-width: 576px) {
            margin-top: 40px;
        }

        h2 {
            font-size: 48px;
            font-weight: 600;
            line-height: 68px;
            color: #000000;
            padding: 15px 0 0 0;

            @media (max-width: 576px) {
                font-size: 38px;
                font-weight: 500;
                line-height: 48px;
            }
        }

        p {
            max-width: 695px;
            margin: 0 auto;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;

            &:last-child {
                padding: 12px 0 0 0;
            }

            @media (max-width: 576px) {
                max-width: 300px;
                margin-top: 20px;
            }
        }
    }

    .hiringVirtualCfoDetailsAlignment {
        padding: 96px 0 0 0;
        max-width: 900px;
        margin: 0 auto;

        @media (max-width: 768px) {
            max-width: 600px;
        }

        @media (max-width: 576px) {
            padding: 46px 0 0 0;
            max-width: 400px;
        }

        .hiringGrid {
            display: grid;
            grid-template-columns: 1fr 460px;
            gap: 92px;
            align-items: center;
            padding: 0 0 120px 0;

            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 50px;
                padding: 0 0 90px 0;
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 50px;
                padding: 0 0 90px 0;
            }

            &:nth-child(even) {
                grid-template-columns: 460px 1fr;

                @media (max-width: 768px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                @media (max-width: 576px) {
                    grid-template-columns: repeat(1, 1fr);
                }
            }

            &:last-child {
                padding: 0;
            }

            .hiring-details-alignment {
                display: grid;
                grid-template-columns: repeat(1, 1fr);

                h3 {
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 42px;
                    color: #000000;

                    @media (max-width: 768px) {
                        text-align: center;
                    }

                    @media (max-width: 576px) {
                        text-align: center;
                    }
                }

                p {
                    padding: 12px 0 0 0;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    text-align: left;
                    color: #000000;

                    @media (max-width: 768px) {
                        text-align: center;
                    }

                    @media (max-width: 576px) {
                        text-align: center;
                    }

                }
            }

            .hiring-details-img {
                width: 460px;

                @media (max-width: 576px) {
                    width: 300px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}