.main-header-wrapper {
    padding: 20px 0;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #fff;
    // position: relative;

    @media (max-width: 576px) {
        top: -1px;
    }

    .main-header-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-wrapper {
            cursor: pointer;

            .img-logo {
                height: 34px;
            }
        }

        .main-links-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            white-space: nowrap;
            gap: 40px;

            .link-text {
                text-decoration: none;
                cursor: pointer;
                color: #000;
                font-weight: 400;
                font-size: 16px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .active {
                color: #007e91;
                font-weight: 500;
                font-size: 16px;
            }

            .menu-wrapper {
                .menu-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                    cursor: pointer;
                }

                .book-keeping-menu-item {
                    ul {
                        li {
                            text-decoration: none;
                            cursor: pointer;
                            color: #000;
                            font-weight: 400;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .enquire-now-button {
            border: 1px solid #000;
            padding: 14px 25px;
            color: #007e91;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            background-color: #fff;
            border-radius: 14px;
            cursor: pointer;
        }
    }

    .mobile-menu-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .menu-wrapper {
            .menu-button {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                cursor: pointer;
            }
        }
    }

    .mobile-view-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9999;
    }

    .mobile-view-sidebar {
        width: 280px;
        position: fixed;
        top: 0;
        right: 0;

        z-index: 9999;
        background: #00edac;
        border-radius: 20px 0 0 20px;

        &.open-sidebar {
            transform: translateX(0);
            transition: 0.4s ease-in-out;
        }

        &.close-sidebar {
            transform: translateX(100%);
            transition: 0.4s ease-in-out;
        }

        .mobile-top-heading {
            background: #fff;
            padding: 20px 20px 20px 12px;
            border-bottom: rgba(255, 255, 255, 0.6);
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 20px 0 0 0;

            img {
                width: 120px;
                display: block;
            }

            .close-icon-alignment {
                width: 24px;
                height: 24px;
            }
        }

        .mobile-view-sidebar-details {
            height: calc(100vh - 64px);
            overflow-y: auto;

            .mobile-view-sidebar-all-details {
                padding: 20px;

                .mobile-view-menu {
                    padding: 10px 0;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 500;
                        color: #fff;
                        text-decoration: none;
                    }

                    .mobile-view-child-menu {
                        .mobile-view-child-menu-name {
                            padding: 10px;

                            a {
                                font-size: 14px;
                                line-height: 21px;
                                font-weight: 500;
                                color: #fff;
                            }
                        }

                        &.open-mobile-view-dropdown {
                            max-height: 200px;
                            overflow-y: auto;
                            transition: 0.4s ease-in-out;
                        }

                        &.hidden-mobile-view-dropdown {
                            max-height: 0px;
                            overflow-y: hidden;
                            transition: 0.4s ease-in-out;
                        }
                    }
                }
            }
        }

        .enquiry-now-button {
            padding: 20px;

            button {
                width: 100%;
                height: 40px;
                border-radius: 24px;
                border: none;
            }
        }
    }
}