.account-outsourcing-banner-section {
    padding: 40px 0 0 0;

    @media (max-width: 768px) {
        padding: 0;
    }

    @media (max-width: 576px) {
        padding: 0;
    }

    .banner-grid {
        display: grid;
        grid-template-columns: 500px 1fr;
        align-items: center;
        gap: 50px;

        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }

        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }

        .bannner-left-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .bannner-left {
                width: 400px;

                @media (max-width: 768px) {
                    width: 350px;
                }

                @media (max-width: 576px) {
                    width: 350px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .banner-right {
            max-width: 600px;

            h1 {
                font-size: 47px;
                font-weight: 600;
                line-height: 50px;
                text-align: left;
                color: #0f3a3e;
            }

            p {
                padding: 22px 0 0 0;
                font-size: 15px;
                font-weight: 400;
                line-height: 21px;
                text-align: left;
            }
        }
    }

    .book-button {
        text-align: center;
        padding: 70px 0 0 0;

        button {
            width: 180px;
            padding: 7px;
            border: none;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            text-align: center;
            background: #00edac;
            border-radius: 16px;
            color: #fff;
            cursor: pointer;
        }
    }
}