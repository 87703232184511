.payableServicesSection {
    padding: 24px 0 0 0;

    .payableServicesHeading {
        text-align: center;

        h2 {
            font-size: 48px;
            font-weight: 600;
            line-height: 68px;
            color: #000000;
            padding: 15px 0 0 0;
            max-width: 750px;
            margin: 0 auto;

            @media (max-width: 576px) {
                font-size: 38px;
                line-height: 52px;
            }
        }
    }

    .payableServicesDetailsAlignment {
        padding: 96px 0 0 0;
        max-width: 856px;
        margin: 0 auto;

        @media (max-width: 576px) {
            padding: 50px 0 0 0;
            max-width: unset;
            margin: unset;
        }

        .payableServicesGrid {
            display: grid;
            grid-template-columns: 1fr 390px;
            gap: 92px;
            align-items: center;
            padding: 0 0 120px 0;

            @media (max-width: 768px) {
                grid-template-columns: 1fr 300px;
                gap: 30px;
                padding: 0 0 40px 0;
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 30px;
                padding: 0 0 40px 0;
            }

            &:nth-child(even) {
                grid-template-columns: 390px 1fr;

                @media (max-width: 768px) {
                    grid-template-columns: 300px 1fr;
                }

                @media (max-width: 576px) {
                    grid-template-columns: repeat(1, 1fr);
                }
            }

            &:last-child {
                padding: 0;
            }

            .payableServices-details-alignment {
                h3 {
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 42px;
                    color: #000000;

                    @media (max-width: 576px) {
                        text-align: center;
                    }

                }

                p {
                    padding: 12px 0 0 0;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    text-align: left;
                    color: #000000;

                    @media (max-width: 576px) {
                        text-align: center;
                    }
                }
            }

            .payableServices-details-img {
                @media (max-width: 576px) {
                    width: 300px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}