.main-home-wrapper {
    .homepage-content-wrapper {
        .home-banner-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 70px;
            padding: 45px 60px;
            background-image: url("../../assets/images/banner-bg-pattern.svg");
            background-repeat: no-repeat;
            background-size: cover;

            h1 {
                font-weight: 600;
                font-size: 55px;
                line-height: 77px;

                @media (max-width: 768px) {
                    text-align: center;
                    line-height: 60px;
                }

                @media (max-width: 576px) {
                    text-align: center;
                    line-height: 60px;
                }
            }

            p {
                font-weight: 600;
                font-size: 30px;
                line-height: 40px;

                @media (max-width: 768px) {
                    text-align: center;
                    margin-top: 10px;
                }

                @media (max-width: 576px) {
                    text-align: center;
                    margin-top: 10px;
                }
            }

            @media (max-width: 768px) {
                flex-direction: column;
                padding: 30px;
                gap: 40px;
                font-size: 35px;
                line-height: 50px;
            }

            @media (max-width: 576px) {
                flex-direction: column;
                padding: 30px;
                gap: 40px;
                font-size: 35px;
                line-height: 50px;
            }

            .banner-text-btn-wrapper {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                gap: 40px;

                @media (max-width: 768px) {
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                }

                @media (max-width: 576px) {
                    align-items: center;
                    justify-content: center;
                    gap: 20px;
                }

                .banner-text-wrapper {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;

                    @media (max-width: 768px) {
                        align-items: center;
                        justify-content: center;
                    }

                    @media (max-width: 576px) {
                        align-items: center;
                        justify-content: center;
                    }
                }

                .banner-btn-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 40px;

                    @media (max-width: 576px) {
                        flex-direction: column;
                        gap: 10px;
                    }

                    .banner-btn {
                        background-color: #00EDAC;
                        text-transform: uppercase;
                        font-family: Anek Tamil;
                        font-size: 25px;
                        font-weight: 600;
                        line-height: 35.2px;
                        text-align: center;
                        border: none;
                        padding: 10px 30px;
                        border-radius: 45.5px;
                        color: #fff;
                        text-wrap: nowrap;
                        cursor: pointer;

                        @media (max-width: 768px) {
                            font-size: 20px;
                            line-height: 30.2px;
                            padding: 10px 25px;
                        }

                        @media (max-width: 576px) {
                            font-size: 20px;
                            line-height: 30.2px;
                            padding: 10px 25px;
                        }
                    }
                }
            }

            .home-banner-img-wrapper {
                @media (max-width: 576px) {
                    width: 350px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .our-services-title {
            font-size: 40px;
            font-weight: 500;
            line-height: 56px;
            text-align: center;
            margin-top: 60px;
            color: #000;
        }

        .our-services-subtitle {
            color: #b3b3b3;
            font-size: 26px;
            font-weight: 500;
            line-height: 56px;
            text-align: center;
            margin-top: 60px;
        }

        .explore-more-button-wrapper {
            margin-top: 90px;

            @media (max-width: 576px) {
                margin-top: 50px;
            }
        }

        .usp-title-wrapper {
            .usp-description {
                font-size: 26px;
                font-weight: 500;
                line-height: 36px;
                text-align: center;
                color: #b3b3b3;
            }
        }

        .our-usp-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 10px;

            .our-usp-details-wrapper {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 84px;

                @media (max-width: 768px) {
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 30px;
                }

                @media (max-width: 576px) {
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 30px;
                }

                .usp-image-wrapper {
                    width: 500px;

                    @media (max-width: 576px) {
                        width: 300px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .usp-card-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 20px;
                    margin-top: 48px;
                    width: 100%;

                    @media (max-width: 576px) {
                        margin-top: 0;
                    }

                    .card-body-content {
                        width: 400px;
                        border: 1px solid #000;
                        border-radius: 20px;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 23px;
                        background-color: #f5fffc;
                        padding: 24px 34px;

                        @media (max-width: 576px) {
                            width: 230px;
                            padding: 20px 30px;
                        }
                    }
                }
            }
        }

        .hire-us-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 62px;

            .our-services-card-wrapper {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                justify-content: center;
                align-items: center;
                gap: 30px;

                @media (max-width: 768px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 30px;
                }

                @media (max-width: 576px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 30px;
                }

                .services-card-content {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    gap: 10px;
                    border: 1px solid;
                    border-radius: 24px;
                    padding: 25px;
                    box-shadow: 0 10px 2px -2px #00edac;

                    &:hover {
                        transition: 0.4s ease-in-out;
                        box-shadow: 1px 9px 28.6px 0px rgba(0, 0, 0, 0.1019607843);
                        background: #00edac;
                        border: 1px solid #00edac;
                    }


                    @media (max-width: 576px) {
                        width: 200px;
                    }

                    .card-image {
                        background-color: #f7f7f7;
                        border-radius: 50%;
                        padding: 12px 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .card-title {
                        font-size: 23px;
                        font-weight: 500;
                        line-height: 32px;

                        @media (max-width: 576px) {
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }

                    .card-body-content {
                        text-wrap: wrap;
                        max-width: 355px;
                    }

                    &:last-child {
                        grid-column: span 2;
                        width: max-content;
                        margin: 0 auto;

                        @media (max-width: 576px) {
                            width: 200px;
                        }
                    }
                }
            }
        }

        .served-to-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .served-to-card-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: 20px;
                flex-wrap: wrap;
                margin-top: 50px;

                @media (max-width: 576px) {
                    margin-top: 20px;
                }

                .served-to-card-content {
                    display: flex;
                    align-items: center;
                    padding: 24px 18px;
                    justify-content: center;
                    flex-direction: column;
                    gap: 10px;
                    border: 1px solid;
                    border-radius: 24px;
                    box-shadow: 0 10px 2px -2px #00edac;
                    width: 227px;

                    &:hover {
                        transition: 0.4s ease-in-out;
                        box-shadow: 1px 9px 28.6px 0px rgba(0, 0, 0, 0.1019607843);
                        background: #00edac;
                        border: 1px solid #00edac;
                    }

                    .card-title {
                        font-size: 23px;
                        font-weight: 500;
                        line-height: 32px;
                    }
                }
            }
        }

        .brands-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 50px;

            .brands-card-wrapper {
                background-color: #f5fffc;
                padding: 28px 52px;

                .brands-image-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 70px;
                }
            }
        }

        .our-clients-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 50px;
            flex-direction: column;

            .our-clients-card-wrapper {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 20px;

                .our-clients-card {
                    background-color: #f5fffc;
                    border: 1px solid #f5fffc;
                    border-radius: 20px;
                    width: 227px;
                    height: 104px;

                    span {
                        visibility: hidden;
                    }
                }
            }
        }
    }
}