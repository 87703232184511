.our-services-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column;

    .our-services-card-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 30px;
        flex-wrap: wrap;

        .services-card-content {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            border: 1px solid;
            border-radius: 24px;
            padding: 0 25px;
            width: 334px;
            height: 134px;
            box-shadow: 0 10px 2px -2px #00edac;

            &:hover {
                transition: 0.4s ease-in-out;
                box-shadow: 1px 9px 28.6px 0px rgba(0, 0, 0, 0.1019607843);
                background: #00edac;
                border: 1px solid #00edac;
            }

            .card-title {
                font-size: 23px;
                font-weight: 500;
                line-height: 32px;
            }

            .card-body-content {
                text-wrap: wrap;
                max-width: 260px;
            }
        }
    }

}