@import url('https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@100..800&display=swap');

body {
  margin: 0;
  font-family: "Anek Tamil", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}

.virtual-banner-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;

  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 576px) {
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 50px;
    color: #0f3a3e;

    @media (max-width: 768px) {
      text-align: center;
    }

    @media (max-width: 576px) {
      text-align: center;
      font-size: 26px;
      font-weight: 500;
      line-height: 32px;
    }
  }

  h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 31.81px;
    text-align: left;
    color: #000;

    @media (max-width: 768px) {
      text-align: center;
    }

    @media (max-width: 576px) {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px;
    }
  }

  ul {
    margin: 0;

    li {
      font-size: 20px;
      line-height: 40px;
      font-weight: 400;
      color: #000000;

      @media (max-width: 576px) {
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
      }
    }
  }
}

.hiringVirtualCfoHeading {
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 40px;
  }

  @media (max-width: 576px) {
    margin-top: 40px;
  }

  h2 {
    font-size: 48px;
    font-weight: 600;
    line-height: 68px;
    color: #000000;
    padding: 15px 0 0 0;

    @media (max-width: 576px) {
      font-size: 30px;
      font-weight: 500;
      line-height: 34px;
    }
  }

  p {
    /* max-width: 695px; */
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;

    &:last-child {
      padding: 12px 0 0 0;
    }

    @media (max-width: 576px) {
      max-width: 400px;
    }
  }
}