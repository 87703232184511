.main-blogs-list-wrapper {
    margin: 20px 0 0 0;

    .blog-card-list-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        align-items: center;
        justify-content: center;


        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
        }


        .card {
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            max-width: 400px;
            margin: auto;
            text-align: center;
            border: none;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .card-image-wrapper {
                width: 100%;
                height: 300px;

                img {
                    border: none;
                    border-top-left-radius: 6px;
                    border-top-right-radius: 6px;
                    width: 100%;
                    height: 100%;
                }
            }

            .card-body-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                width: auto;
                padding: 20px;

                .card-content-wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 6px;
                    width: 100%;

                    .blog-description {
                        color: grey;
                        font-size: 18px;
                        font-family: "Anek Tamil", sans-serif;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 80px;
                    }

                    h2 {
                        cursor: pointer;
                        height: 110px;
                    }
                }

                .card-button-wrapper {
                    width: 100%;
                    margin: 10px;

                    button {
                        font-family: "Anek Tamil", sans-serif;
                        border: none;
                        outline: 0;
                        display: inline-block;
                        padding: 8px;
                        color: #000;
                        background-color: #00EDAC;
                        text-align: center;
                        cursor: pointer;
                        width: 100%;
                        font-size: 18px;
                        border-radius: 6px;

                        &:hover {
                            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                        }
                    }
                }
            }
        }
    }
}