.ourProcess-section {
    padding: 60px 0 0 0;

    .ourProcess-heading {
        text-align: center;

        h2 {
            font-size: 48px;
            font-weight: 600;
            line-height: 68px;
            color: #000000;
        }

        p {
            max-width: 432px;
            margin: 0 auto;
            font-size: 16px;
            font-weight: 400;
            line-height: 22px;
        }
    }

    .ourProcess-details-alignment {
        padding: 82px 0 0 0;

        @media (max-width: 576px) {
         padding: 40px 0 0 0;
        }

        .ourProcess-details-grid {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 58px;
            max-width: 1047px;
            margin: 0 auto;

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 30px;
            }

            .ourProcess-details-grid-item {
                text-align: center;

                .icon-alignment {
                    position: relative;

                    .procees-arrow {
                        position: absolute;
                        right: -25px;
                        top: 50%;
                        transform: translateY(-50%);

                        @media (max-width: 576px) {
                            display: none;
                        }

                        img {
                            cursor: pointer;
                        }
                    }
                }

                p {
                    font-size: 19px;
                    font-weight: 500;
                    line-height: 23px;
                    color: #000000;
                    padding: 54px 0 0 0;

                    @media (max-width: 576px) {
                        padding: 10px 0 0 0;
                    }
                }
            }
        }
    }
}