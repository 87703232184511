.main-blog-details-wrapper {
    margin-top: 50px;

    .blog-details-alignment {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 40px;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        @media (max-width: 576px) {
            flex-direction: column;
        }


        .blog-details-left-alignment {
            .blog-details-content-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 50px;

                .blog-details-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    h1 {
                        font-size: 40px;
                        font-weight: 500;
                        line-height: 56px;
                        color: #000000;
                        text-align: center;
                        font-family: "Anek Tamil", sans-serif;
                    }
                }

                .blog-details-body {

                    .blog-details-body-content {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        gap: 40px;

                        .benefit-title {
                            font-size: 26px;
                            font-weight: 500;
                            line-height: 30px;
                            text-align: justify;
                            font-family: "Anek Tamil", sans-serif;
                            margin-bottom: 10px;
                        }

                        .benefit-overview {
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 24px;
                            text-align: justify;
                            font-family: "Anek Tamil", sans-serif;
                        }

                        .benefits-list {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 40px;

                            .benefit-description {
                                font-size: 20px;
                                font-weight: 400;
                                line-height: 24px;
                                text-align: justify;
                                font-family: "Anek Tamil", sans-serif;
                            }
                        }

                        .blog-guide-content-wrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: flex-start;
                            gap: 40px;

                            .guide-list {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: flex-start;
                                gap: 30px;

                                .list-title {
                                    font-size: 22px;
                                    font-weight: 500;
                                    line-height: 24px;
                                    text-align: justify;
                                    font-family: "Anek Tamil", sans-serif;
                                    margin-bottom: 5px;
                                }

                                .list-description {
                                    font-size: 18px;
                                    font-weight: 400;
                                    line-height: 24px;
                                    text-align: justify;
                                    font-family: "Anek Tamil", sans-serif;
                                }
                            }
                        }
                    }
                }
            }
        }

        .blog-details-right-alignment {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 40px;

            @media (max-width: 768px) {
                width: 100%;
            }

            @media (max-width: 576px) {
                width: 100%;
            }

            .blog-details-right-alignment-item {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                width: 350px;
                margin: auto;
                background-color: #fff;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                border: none;
                border-radius: 6px;
                padding: 20px;

                @media (max-width: 576px) {
                    width: auto;
                }


                img {
                    height: 100px;
                }

                h3 {
                    font-family: "Anek Tamil", sans-serif;
                    color: #8b8b8b;
                    text-align: left;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 25px;
                    text-wrap: wrap;
                    cursor: pointer;

                    &:hover {
                        color: #000000;
                    }
                }

                &:hover {
                    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}