.catch-table-section {
    padding: 60px 0 0 0;
    max-width: 990px;
    margin: 0 auto;

    .catch-table {
        table {
            width: 100%;

            thead {
                tr {
                    th {
                        background: #00f4ad;
                        padding: 10px;
                        font-size: 28px;
                        font-weight: 500;
                        line-height: 36px;
                        text-align: center;

                        &:first-child {
                            border-radius: 25px 0 0 25px;
                        }

                        &:last-child {
                            border-radius: 0 25px 25px 0;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 20px 0;

                        p {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 22px;
                            text-align: center;
                        }

                        &:first-child {
                            border-radius: 25px 0 0 25px;
                        }

                        &:last-child {
                            border-radius: 0 25px 25px 0;
                        }
                    }

                    &:nth-child(even) {
                        td {
                            background: #f2f2f2;
                        }
                    }
                }
            }
        }

        table thead tr th:first-child {
            width: 50%;
        }

        table thead tr th:last-child {
            width: 50%;
        }
    }

    .catch-button {
        margin: 70px 0 0 0;
        text-align: center;
        position: relative;
        z-index: 9;

        button {
            border: 2px solid #000000;
            width: 338px;
            border-radius: 46px;
            background: #ffffff;
            font-size: 28px;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            cursor: pointer;
            padding: 11px 0;
            position: relative;
            z-index: 9;

            &:hover {
                transition: 0.4s ease-in-out;
                box-shadow: 1px 9px 28.6px 0px rgba(0, 0, 0, 0.1019607843);
                background: #00edac;
                border: 1px solid #00edac;
            }
        }

        .bg {
            content: "";
            position: absolute;
            height: 100%;
            background: #00f4ad;
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 46px;
            width: 338px;
            cursor: pointer;

            &:hover {
                transition: 0.4s ease-in-out;
                box-shadow: 1px 9px 28.6px 0px rgba(0, 0, 0, 0.1019607843);
                background: unset;
            }
        }
    }

    .dedication-section {
        padding: 136px 0 0 0;
        max-width: 790px;
        margin: 0 auto;

        h3 {
            font-size: 48px;
            font-weight: 600;
            line-height: 58px;
            text-align: center;
            color: #000;
        }

        p {
            padding: 14px 0 0 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
        }
    }
}