.virtual-banner-section {
    padding: 40px;

    @media (max-width: 576px) {
        padding: 10px;
    }

    .virtual-banner-grid {
        display: grid;
        grid-template-columns: 500px 1fr;
        gap: 82px;
        align-items: center;

        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        .virtual-banner-img-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .virtual-banner-img {
                width: 500px;

                @media (max-width: 768px) {
                    width: 400px;
                }

                @media (max-width: 576px) {
                    width: 300px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }

        .virtual-banner-text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 30px;

            @media (max-width: 768px) {
                align-items: center;
                justify-content: center;
            }

            @media (max-width: 576px) {
                align-items: center;
                justify-content: center;
            }

            h1 {
                font-size: 48px;
                font-weight: 600;
                line-height: 50px;
                color: #0f3a3e;

                @media (max-width: 768px) {
                    text-align: center;
                }

                @media (max-width: 576px) {
                    text-align: center;
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 32px;
                }
            }

            h2 {
                font-size: 30px;
                font-weight: 500;
                line-height: 31.81px;
                text-align: left;
                color: #000;

                @media (max-width: 768px) {
                    max-width: 300px;
                    text-align: center;
                }

                @media (max-width: 576px) {
                    max-width: 300px;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 22px;
                }
            }

            ul {
                li {
                    font-size: 20px;
                    line-height: 40px;
                    font-weight: 400;
                    color: #000000;

                    @media (max-width: 576px) {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}