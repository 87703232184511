.what-virtual-cfo-section {
    padding: 60px 0 0 0;
    max-width: 1130px;
    margin: 0 auto;

    .what-virtual-cfo-heading {
        text-align: center;

        h2 {
            font-size: 48px;
            font-weight: 600;
            line-height: 68px;
            color: #000000;
        }
    }

    .what-virtual-cfo-details-alignment {
        padding: 45px 0 0 0;

        .what-virtual-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 28px 25px;

            @media (max-width: 768px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .what-virtual-cfo-box {
                border: 1.74px solid #000000;
                padding: 34px 30px 40px 30px;
                border-radius: 26px;

                h4 {
                    font-size: 23px;
                    font-weight: 500;
                    line-height: 26px;
                    color: #000000;
                }

                p {
                    padding: 12px 0 0 0;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #000000;

                }
            }
        }
    }
}