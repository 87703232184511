.account-payeble-banner-section {


    .account-payeble-banner-grid {
        display: grid;
        grid-template-columns: 500px 1fr;
        gap: 82px;
        align-items: center;

        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
        }

        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
        }

        .account-payeble-banner-img-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            .account-payeble-banner-img {
                width: 450px;

                @media (max-width: 768px) {
                    width: 350px;
                }

                @media (max-width: 576px) {
                    width: 250px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }

        .account-payeble-banner-text {
            h1 {
                font-size: 48px;
                font-weight: 600;
                line-height: 50px;
                color: #0f3a3e;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                padding: 22px 0 0 0;
                color: #000000;
            }
        }
    }
}