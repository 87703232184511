.watch-catch-section {
    padding: 60px 0 0 0;

    .watch-catch-heading {
        text-align: center;
        max-width: 990px;
        margin: 0 auto;
        h2 {
            font-size: 48px;
            font-weight: 600;
            line-height: 68px;
            color: #000;
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            text-align: center;
            color: #000000;
            padding: 18px 0 0 0;
        }
    }
}
