.pricingStructure-section {
    padding: 80px 0 0 0;

    .pricingStructure-heading {
        text-align: center;

        h2 {
            font-size: 40px;
            font-weight: 500;
            line-height: 56px;
            color: #000000;
        }

        p {
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;

            color: #0000004d;
        }

        .bookcallButton {
            padding: 60px 0 0 0;

            button {
                width: 180px;
                padding: 7px;
                background: #00edac;
                border-radius: 16px;
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;
                text-align: center;
                cursor: pointer;
                border: none;
                color: #fff;
            }
        }
    }

    .pricingStructure-all-details {
        max-width: 1076px;
        margin: 0 auto;

        @media (max-width: 576px) {
            max-width: 476px;
        }

        label {
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            color: #0000004d;

            @media (max-width: 576px) {
                text-align: center;
            }
        }

        .pricing-box {
            border: 1px solid #00000080;
            border-radius: 20px;
            padding: 22px 34px;

            .pricicng-top {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    font-size: 40px;
                    font-weight: 500;
                    line-height: 56px;
                    color: #000000;
                }

                .rightSide {
                    p {
                        font-size: 50px;
                        font-weight: 600;
                        line-height: 70px;
                        color: #000000;

                        span {
                            font-size: 20.15px;
                            font-weight: 500;
                            line-height: 28.38px;
                            color: #0000004d;
                        }
                    }
                }
            }

            .pricing-bottom {
                padding: 64px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: 576px) {
                    flex-direction: column;
                    gap: 20px;
                    padding: 30px 0 0 0;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #00000080;
                    max-width: 324px;
                }

                button {
                    width: 180px;
                    background: #00edac;
                    border: none;
                    border-radius: 16px;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 28px;
                    padding: 7px;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
}