.all-served-to-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .all-our-services-title {
        font-size: 40px;
        font-weight: 500;
        line-height: 56px;
        text-align: center;
        color: #000;
    }

    .all-served-to-card-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 50px;

        @media (max-width: 576px) {
            margin-top: 30px;
        }

        .all-served-to-card-content {
            display: flex;
            align-items: center;
            padding: 24px 18px;
            justify-content: center;
            flex-direction: column;
            gap: 10px;
            border: 1px solid;
            border-radius: 24px;
            box-shadow: 0 10px 2px -2px #00edac;
            width: 227px;

            &:hover {
                transition: 0.4s ease-in-out;
                box-shadow: 1px 9px 28.6px 0px rgba(0, 0, 0, 0.1019607843);
                background: #00edac;
                border: 1px solid #00edac;
            }

            .all-card-title {
                font-size: 23px;
                font-weight: 500;
                line-height: 32px;
            }
        }
    }
}