.whyChooseUs-section {
    padding: 60px 0 0 0;

    h2 {
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        color: #000000;
    }

    .whyChooseUs-details-alignment {
        padding: 60px 0 0 0;

        .whyChooseUs-grid {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 110px;

            @media (max-width: 576px) {
                flex-direction: column;
                gap: 50px;
            }

            .whyChooseUs-img {
                width: 475px;

                @media (max-width: 576px) {
                    width: 300px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .whyChooseUs-right-side-alignment {
                max-width: 422px;

                .list-name-alignment {
                    padding: 0 0 20px 0;

                    &:last-child {
                        padding: 0;
                    }

                    h6 {
                        padding: 0 0 5px 0;
                        font-size: 19px;
                        font-weight: 500;
                        line-height: 26px;
                        color: #000;

                        @media (max-width: 576px) {
                            text-align: center;
                        }
                    }

                    p {
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 22px;
                        color: #00000080;

                        @media (max-width: 576px) {
                            text-align: center;
                        }

                    }
                }
            }
        }
    }
}