.automation-services-section {
    padding: 60px 0 0 0;

    .automation-services-heading {
        text-align: center;
        max-width: 630px;
        margin: 0 auto;

        h2 {
            font-size: 48px;
            font-weight: 600;
            line-height: 60px;
            color: #000000;

            @media (max-width: 576px) {
                font-size: 38px;
                line-height: 52px;
            }
        }

        p {
            padding: 25px 0 0 0;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            color: #000000;
        }
    }

    .automation-services-details-alignment {
        padding: 80px 0 0 0;
        max-width: 920px;
        margin: 0 auto;

        .automation-services-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
            gap: 30px;
            margin-bottom: 92px;

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .automation-services-grid-item {
                .automation-services-box {
                    border: 1.74px solid #000000;
                    padding: 0 34px;
                    border-radius: 26px;
                    margin: 0 0 30px 0;
                    min-height: 350px;
                    max-width: 500px;

                    h4 {
                        font-size: 35px;
                        font-weight: 600;
                        line-height: 50px;
                        color: #000000;
                        padding: 30px 0 0 0;
                        max-width: 500px;
                    }

                    p {
                        max-width: 200px;
                        padding: 10px 0 0 0;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 22px;
                        color: #000000;
                        max-width: 500px;
                    }

                    .link {
                        padding: 12px 0 30px 0;

                        a {
                            display: block;
                            color: #000;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 30px;
                            text-align: left;
                            padding: 0 0 8px 0;
                            max-width: 500px;
                            text-wrap: wrap;
                        }
                    }
                }
            }
        }
    }
}