.explore-sevices-button {
    text-align: center;
    padding: 60px 0 0 0;
    button {
        width: 180px;
        padding: 7px;
        border: none;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        background: #00edac;
        border-radius: 16px;
        color: #fff;
    }
}
