.provide-finaccle-section {
    padding: 60px 0 0 0;

    h2 {
        text-align: center;
        font-size: 47px;
        font-weight: 600;
        line-height: 67px;
        color: #000000;

        @media (max-width: 576px) {
            font-size: 32px;
            font-weight: 500;
            line-height: 36px;
        }
    }

    .provide-finaccle-details-alignment {
        padding: 34px 0 0 0;

        .provide-finaccle-child-details {
            h3 {
                font-size: 47px;
                font-weight: 600;
                line-height: 67px;
                color: #000;
                padding: 0 0 34px 0;

                @media (max-width: 768px) {
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 50px;
                }

                @media (max-width: 576px) {
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 50px;
                }
            }

            .four-provide-finaccle-grid {
                grid-template-columns: repeat(2, 1fr) !important;

                @media (max-width: 768px) {
                    grid-template-columns: repeat(2, 1fr) !important;
                }

                @media (max-width: 576px) {
                    grid-template-columns: repeat(1, 1fr) !important;
                }
            }

            .provide-finaccle-grid {
                display: grid;
                gap: 18px;

                .box-alignment {
                    border: 1.7px solid #000000;
                    border-radius: 26px;
                    padding: 55px 36px 40px 36px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    @media (max-width: 768px) {
                        padding: 36px;
                    }

                    @media (max-width: 576px) {
                        padding: 36px;
                    }

                    img {
                        max-width: 54px;
                    }

                    h4 {
                        font-size: 28px;
                        font-weight: 500;
                        line-height: 36px;
                        color: #000000;
                        padding: 45px 0 0 0;

                        @media (max-width: 768px) {
                            padding: 20px 0 0 0;
                        }

                        @media (max-width: 576px) {
                            font-size: 22px;
                            font-weight: 500;
                            line-height: 30px;
                            padding: 20px 0 0 0;
                        }
                    }

                    p {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        color: #8E8E8E;
                        padding: 23px 0 0 0;

                        @media (max-width: 768px) {
                            padding: 10px 0 0 0;
                        }

                        @media (max-width: 576px) {
                            padding: 10px 0 0 0;
                        }
                    }
                }
            }
        }
    }

    // .book-button {
    //     text-align: center;
    //     padding: 60px 0 0 0;
    //     button {
    //         width: 180px;
    //         padding: 7px;
    //         border: none;
    //         font-size: 20px;
    //         font-weight: 600;
    //         line-height: 28px;
    //         text-align: center;
    //         background: #00edac;
    //         border-radius: 16px;
    //         color: #fff;
    //         cursor: pointer;
    //     }
    // }
}