.dedication-section {
    max-width: 790px;
    margin: 90px auto 0 auto;

    h3 {
        font-size: 48px;
        font-weight: 600;
        line-height: 58px;
        text-align: center;
        color: #000;
    }

    p {
        padding: 14px 0 0 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
    }
}

.book-button {
    text-align: center;
    padding: 60px 0 0 0;

    button {
        min-width: 180px;
        padding: 8px 20px;
        border: none;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        background: #00edac;
        border-radius: 16px;
        color: #fff;
        cursor: pointer;
    }
}