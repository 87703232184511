.software-expertise-section {
    text-align: center;

    h2 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        color: #000000;
    }

    p {
        padding: 15px 0 0 0;
        color: #00000080;
        font-size: 16px;
        line-height: 22px;
        max-width: 528px;
        margin: 0 auto;
    }

    .expertise-logo-details {
        margin: 20px 0 0 0;
        background: #f8f8f8ad;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;

        @media (max-width: 768px) {
            flex-wrap: wrap;
            gap: 20px;
        }

        @media (max-width: 576px) {
            flex-direction: column;
            gap: 0;
        }


        .logo-alignment {
            min-height: 92px;
            display: flex;
            align-items: center;

            img {
                cursor: pointer;
            }
        }
    }
}