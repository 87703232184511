.testimonial-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    flex-direction: column;

    .testimonial-title-wrapper {
        .testimonial-description {
            font-size: 26px;
            font-weight: 500;
            line-height: 36px;
            text-align: center;
            color: #b3b3b3;
        }

        .testimonial-title {
            font-size: 40px;
            font-weight: 500;
            line-height: 56px;
            text-align: center;
            margin-top: 60px;
        }
    }

    .testimonial-card-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
        }


        .testimonial-card {
            .first-card {
                background-color: #00edac;
            }

            .testimonial-content {
                border: 1px solid #000;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                background-color: #fff;
                width: 227px;
                height: 216px;
                border-radius: 20px;
                padding: 20px;
                transition: 0.4s ease-in-out;
                cursor: pointer;

                @media (max-width: 576px) {
                    height: 190px;
                }

                .semicolor-content {
                    font-size: 56px;
                    font-weight: 700;
                    line-height: 72px;
                    font-style: italic;
                    color: #00edac;
                }

                .testimonial-text {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                }

                &:hover {
                    transition: 0.4s ease-in-out;
                    box-shadow: 1px 9px 28.6px 0px #0000001a;
                    background: #00edac;
                    border: 1px solid #00edac;

                    .semicolor-content {
                        color: #fff;
                    }

                    .testimonial-text {
                        color: #fff;
                    }
                }

                &.testimal-active {
                    transition: 0.4s ease-in-out;
                    box-shadow: 1px 9px 28.6px 0px #0000001a;
                    background: #00edac;
                    border: 1px solid #00edac;

                    .semicolor-content {
                        color: #fff;
                    }

                    .testimonial-text {
                        color: #fff;
                    }
                }
            }

            .testimonial-client {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 12px;
                margin: 18px 0 0 0;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                span {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 21px;
                }
            }
        }
    }
}