.catch-option-section {
    padding: 60px 0 0 0;
    max-width: 990px;
    margin: 0 auto;

    .catch-option-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .catch-option-box {
            border: 1px solid #000000;
            border-radius: 25px;
            padding: 30px 24px 30px 30px;

            .box-grid {
                display: grid;
                grid-template-columns: 1fr 70px;
                align-items: flex-end;
                gap: 30px;

                .box-details-alignment {
                    h3 {
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 32px;
                        color: #000000;
                    }

                    p {
                        padding: 10px 0 0 0;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 22px;
                        color: #8e8e8e;
                    }
                }

                .icon-box {
                    width: 100%;
                    height: 70px;
                    border-radius: 50%;
                    box-shadow: 0px 4px 4px 0px #0000001a;
                    background: #f7f7f7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}