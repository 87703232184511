.virtual-cfo-tab-section {
    padding: 74px 0 0 0;


    @media (max-width: 768px) {
        padding: 40px 0 0 0;
    }

    .virtual-cfo-tab-flex {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px 24px;

        @media (max-width: 576px) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }

        .cfo-box-alignment {
            border: 1.74px solid #000000;
            padding: 25px 22px;
            border-radius: 26px;
            width: 32%;

            @media (max-width: 768px) {
                width: 90%;
            }

            @media (max-width: 576px) {
                width: auto;
            }

            .cfo-grid-alignment {
                display: grid;
                grid-template-columns: 30px 1fr;
                gap: 10px;
                align-items: center;

                .rightSign {
                    width: 100%;
                    height: 30px;
                    border-radius: 50%;
                    background: #00f4ad;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                p {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 50px;
                    color: #000000;
                    text-align: center;

                    @media (max-width: 768px) {
                        line-height: 30px;
                    }

                    @media (max-width: 576px) {
                        line-height: 30px;
                    }
                }
            }

            &:nth-child(2) {
                width: 56%;

                @media (max-width: 768px) {
                    width: 80%
                }

                @media (max-width: 576px) {
                    width: auto;
                }
            }

            &:nth-child(3) {
                width: 27%;

                @media (max-width: 768px) {
                    width: 40%;
                }

                @media (max-width: 576px) {
                    width: auto;
                }
            }

            &:nth-child(4) {
                width: 17%;

                @media (max-width: 768px) {
                    width: 40%;
                }

                @media (max-width: 576px) {
                    width: auto;
                }
            }

            &:nth-child(5) {
                width: 16%;

                @media (max-width: 768px) {
                    width: 40%;
                }

                @media (max-width: 576px) {
                    width: auto;
                }
            }

            &:nth-child(6) {
                width: 16%;

                @media (max-width: 768px) {
                    width: 30%;
                }

                @media (max-width: 576px) {
                    width: auto;
                }
            }

            &:nth-child(7) {
                width: 26%;

                @media (max-width: 576px) {
                    width: auto;
                }
            }
        }
    }

    .book-call-button {
        padding: 60px 0 0 0;
        text-align: center;

        button {
            width: 180px;
            padding: 7px;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            color: #FFFFFF;
            cursor: pointer;
            background: #00EDAC;
            border-radius: 16px;
            border: none;

        }
    }
}