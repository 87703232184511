.main-footer-wrapper {
    margin-top: 95px;

    .footer-content-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        .footer-logo-links-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 66px;
            width: 100%;

            .logo-wrapper {
                .img-logo {
                    height: 34px;
                }
            }

            .main-footer-links-wrapper {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
                gap: 90px;


                @media (max-width: 768px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 40px;
                }

                @media (max-width: 576px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 40px;
                }

                .footer-links-title {
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 21px;
                    margin-bottom: 32px;
                }

                .remove-margin {
                    margin: 0;
                }

                .content-alignment {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    gap: 16px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;

                    .service-item {
                        cursor: pointer;
                        text-wrap: nowrap;

                        &:last-child {
                            margin-bottom: 32px;
                        }
                    }
                }

                .left-footer-links-wrapper {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 140px;
                    border-right: 1px solid #D6D8DE;
                    width: 100%;
                    padding-right: 90px;

                    @media (max-width: 768px) {
                        grid-template-columns: repeat(4, 1fr);
                        border-bottom: 1px solid #D6D8DE;
                        border-right: none;
                        gap: 60px;
                        padding-right: 0;
                        padding-bottom: 40px;
                    }

                    @media (max-width: 576px) {
                        grid-template-columns: repeat(2, 1fr);
                        border-bottom: 1px solid #D6D8DE;
                        border-right: none;
                        gap: 20px;
                        padding-right: 0;
                        padding-bottom: 40px;
                    }

                }

                .right-footer-links-wrapper {
                    width: 100%;
                    height: 100%;

                    .footer-content-contactus {
                        padding-left: 30px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-direction: column;
                        gap: 20px;

                        @media (max-width: 768px) {
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                        }

                        @media (max-width: 576px) {
                            align-items: center;
                            justify-content: center;
                            padding: 0;
                        }

                        .address-wrapper {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            flex-direction: column;
                            gap: 10px;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16px;
                        }

                        .social-wrapper {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            flex-direction: column;
                            gap: 30px;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16px;

                            @media (max-width: 768px) {
                                align-items: center;
                                justify-content: center;
                                gap: 20px;
                            }

                            @media (max-width: 576px) {
                                align-items: center;
                                justify-content: center;
                                gap: 20px;
                            }


                            .call-now-button {
                                background-color: #fff;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 18px;
                                padding: 5px 45px;
                                border: 1px solid #000;
                                border-radius: 5px;
                                text-decoration: none;
                                color: #000;
                            }

                            .follow-us-wrapper {
                                display: flex;
                                align-items: flex-start;
                                justify-content: flex-start;
                                flex-direction: column;
                                gap: 10px;

                                @media (max-width: 768px) {
                                    align-items: center;
                                    justify-content: center;
                                }

                                @media (max-width: 576px) {
                                    align-items: center;
                                    justify-content: center;
                                }


                                .social-icons-wrapper {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: flex-start;
                                    gap: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-rights-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 40px;
        padding-left: 32px;
        font-size: 14px;
    }
}