.retro-services-section {
    padding: 60px 0 0 0;
    max-width: 990px;
    margin: 0 auto;
    text-align: center;

    h3 {
        font-size: 48px;
        font-weight: 600;
        line-height: 58px;
        color: #000;
    }

    h4 {
        font-size: 24px;
        font-weight: 600;
        color: #000;
    }

    .retro-services-details-alignment {
        padding: 20px 0 0 0;

        .retro-services-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 14px 30px;

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .retro-services-grid-item {
                border: 1px solid #000000;
                border-radius: 25px;
                padding: 22px;
                min-height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    text-align: center;
                    color: #000;
                    max-width: 308px;
                    margin: 0 auto;
                }
            }
        }

        .retro-services-alignment {
            padding: 150px 0 0 0;

            textarea {
                width: 100%;
                height: 432px;
                border: 1px solid #000000;
                border-radius: 25px;
                resize: none;
            }
        }
    }
}