.software-expertise-main {
    padding: 60px 0 0 0;
}

.payableServicesHeading {
    text-align: center;

    h2 {
        font-size: 48px;
        font-weight: 600;
        line-height: 68px;
        color: #000000;
        padding: 15px 0 0 0;
        max-width: 750px;
        margin: 0 auto;

        @media (max-width: 576px) {
            font-size: 38px;
            line-height: 52px;
        }
    }
}