.catchup-accounting-banner-section {

    .catchup-accounting-banner-grid {
        display: grid;
        grid-template-columns: 500px 1fr;
        gap: 82px;
        align-items: center;

        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .banner-img-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .banner-img {
                width: 500px;

                @media (max-width: 576px) {
                    width: 300px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }

        .catchup-banner-details {
            h1 {
                font-size: 48px;
                font-weight: 600;
                line-height: 50px;
                color: #000;
            }

            p {
                padding: 22px 0 0 0;
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #000000;
            }
        }
    }

    .catchup-accounting-bottom-alignment {
        .catchup-accounting-bottom-grid {
            display: grid;
            grid-template-columns: 1fr 559px;
            gap: 38px;
            align-items: center;

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
            }

            .catchup-team-details {
                h2 {
                    font-size: 40px;
                    font-weight: 600;
                    line-height: 48px;
                    color: #000000;

                    @media (max-width: 576px) {
                        text-align: center;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 42px;
                    }
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 21px;
                    color: #000;
                    padding: 24px 0 0 0;
                    max-width: 405px;

                    @media (max-width: 576px) {
                        text-align: center;
                        max-width: unset;
                    }
                }
            }

            .team-img-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;

                .team-img {
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}