.main-aboutus-wrapper {
    .aboutus-content-wrapper {
        .about-finaccle-title {
            font-size: 24px;
            font-weight: bold;
        }

        .top-content {
            color: #808080;
            font-size: 18px;
        }

        .highlight-content {
            color: #00edac;
            font-size: 18px;
        }

        .aboutus-banner-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 120px;
            background: #00edac1f;
            padding: 32px 125px;

            @media (max-width: 768px) {
                gap: 50px;
                padding: 32px;
            }

            @media (max-width: 576px) {
                flex-direction: column;
                gap: 50px;
                padding: 32px;
            }



            .banner-title {
                font-weight: 600;
                font-size: 54px;
                line-height: 72px;

                @media (max-width: 768px) {
                    font-size: 44px;
                    line-height: 52px;
                }

                @media (max-width: 576px) {
                    font-size: 44px;
                    line-height: 62px;
                    text-align: center;
                }
            }

            .aboutus-small-text {
                font-size: 22px;
                font-weight: normal;
                line-height: 30px;

                @media (max-width: 576px) {
                    text-align: center;
                }
            }

            .right-side-img-alignment {
                width: 390px;

                @media (max-width: 576px) {
                    width: 300px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }

        .aboutus-content-alignment {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 70px;
            flex-direction: column;
            margin-top: 70px;

            .about-finaccle-wrapper {
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                align-items: center;
                justify-content: center;
                gap: 115px;

                @media (max-width: 768px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 50px;
                }

                @media (max-width: 576px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 50px;
                }


                .about-member-image-wrapper {
                    height: 100%;
                }

                .member-left-alignment {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .member-left-image-alignment {
                        position: relative;

                        .member-image {
                            img {
                                height: 85px;
                            }
                        }

                        .member-name {
                            position: absolute;
                            left: -40px;
                            bottom: -8px;
                            background: #00EDAC;
                            padding: 4px 12px;
                            border: none;
                            border-radius: 14px;
                            font-family: Anek Tamil;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 19.71px;
                            text-align: left;
                            text-transform: uppercase;
                            color: #fff;
                        }
                    }
                }

                .member-right-alignment {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    @media (max-width: 768px) {
                        align-items: center;
                        justify-content: center;
                    }

                    @media (max-width: 576px) {
                        align-items: center;
                        justify-content: center;
                    }

                    .member-right-image-alignment {
                        position: relative;

                        .member-image {
                            img {
                                height: 85px;
                            }
                        }

                        .member-right-name {
                            position: absolute;
                            left: 50px;
                            bottom: 2px;
                            background: #00EDAC;
                            padding: 4px 12px;
                            border: none;
                            border-radius: 14px;
                            font-family: Anek Tamil;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 19.71px;
                            text-align: left;
                            text-transform: uppercase;
                            color: #fff;
                        }
                    }
                }

                .about-desc-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 28px;
                    flex-direction: column;
                    text-align: center;
                    max-width: 640px;
                }
            }
        }


        .mission-vision-wrapper {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 54px;

            @media (max-width: 576px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 30px;
            }

            .mission-vision-card {
                border: 1px solid #000;
                border-radius: 20px;
                padding: 30px 35px;
                text-align: center;
                height: 260px;

                @media (max-width: 768px) {
                    height: 460px;
                }

                @media (max-width: 576px) {
                    height: auto;
                }

                button {
                    background-color: #00edac;
                    border: 1px solid #00edac;
                    color: #fff;
                    border-radius: 18px;
                    padding: 4px 16px;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                }

                p {
                    color: #8b8b8b;
                    text-align: center;
                    font-size: 18px;
                    line-height: 25px;
                    // font-weight: 300;
                    padding: 28px 0 0 0;
                }
            }
        }

        .team-members-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;

            .team-member-card {
                position: relative;

                .team-member-image-wrapper {
                    border-radius: 20px;
                    position: relative;

                    .member-image {
                        border-radius: 20px;
                        height: 233px;
                    }
                }

                .team-member-content-wrapper {
                    background-color: #fff;
                    border-radius: 20px;
                    position: absolute;
                    top: 75%;
                    box-shadow: 0px 4px 29.6px 0px #0000001a;
                    padding: 26px 50px;
                }
            }
        }

        .aboutus-details-section-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 52px;
            padding: 60px 0 0 0;

            @media (max-width: 768px) {
                flex-direction: column;
                gap: 20px;
            }

            @media (max-width: 576px) {
                flex-direction: column;
                gap: 20px;
            }

            .details-section-content {
                width: 428px;

                @media (max-width: 576px) {
                    width: auto;
                }


                .section-wrapper {
                    padding: 0 0 32px 0;

                    .section-title {
                        font-size: 24px;
                        font-weight: 500;
                        line-height: 32px;
                        color: #000000;

                        @media (max-width: 576px) {
                            text-align: center;
                        }

                    }

                    .section-description {
                        font-size: 16px;
                        font-weight: 300;
                        line-height: 23px;
                        color: #00000080;
                        padding: 10px 0 0 0;

                        @media (max-width: 576px) {
                            text-align: center;
                        }

                    }
                }
            }

            .details-section-image {
                width: 500px;
                display: flex;
                justify-content: center;

                @media (max-width: 576px) {
                    width: 350px;
                }

                img {
                    max-width: 100%;
                    display: block;
                }
            }
        }
    }

    .softwrae-expertise-details {
        padding: 76px 0 0 0;
    }

    .aboutus-button-alignment {
        margin-top: 90px;
    }

    .our-promise-section {
        padding: 60px 0 0 0;

        .our-promise-heading {
            text-align: center;

            h2 {
                font-size: 23px;
                font-weight: 500;
                line-height: 32px;
                color: #000000;
            }

            p {
                padding: 12px 0 0 0;
                font-size: 16px;
                font-weight: 300;
                line-height: 22px;
                text-align: center;
                color: #00000080;
                max-width: 528px;
                margin: 0 auto;
            }
        }

        h3 {
            padding: 60px 0 0 0;
            font-size: 23px;
            font-weight: 500;
            line-height: 32px;
            text-align: center;
            max-width: 648px;
            margin: 0 auto;
        }
    }

    .connect-button {
        text-align: center;
        padding: 60px 0 0 0;

        button {
            padding: 16px 25px;
            background: #00edac;
            border-radius: 20px;
            font-size: 23px;
            font-weight: 600;
            line-height: 32px;
            cursor: pointer;
            border: none;
            color: #fff;
        }
    }
}