.main-latest-blog-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 55px;
    margin: 145px 0 0 0;

    @media (max-width: 576px) {
        margin: 70px 0 0 0;
    }

    .latest-blog-post-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 55px;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        @media (max-width: 576px) {
            flex-direction: column;
        }


        .blog-title-wrapper {
            .our-services-title {
                margin-top: 0;
                font-size: 40px;
                font-weight: 500;
                line-height: 56px;
                text-align: left;

                @media (max-width: 768px) {
                    text-align: center;
                }

                @media (max-width: 576px) {
                    text-align: center;
                }

            }

            .blog-description {
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;

                @media (max-width: 768px) {
                    text-align: center;
                }

                @media (max-width: 576px) {
                    text-align: center;
                }

            }
        }

        .blog-card-wrapper {
            position: relative;
            width: 100%;

            &::before {
                content: "";
                position: absolute;
                top: -50px;
                left: 0;
                background-color: #f5fffc;
                border-radius: 20px;
                width: 100%;
                height: 154px;

                @media (max-width: 576px) {
                    display: none;
                }
            }

            .blog-image-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;
                position: relative;

                @media (max-width: 576px) {
                    flex-direction: column;
                }

                img {
                    cursor: pointer;
                    width: 150px;
                    height: 120px;
                }
            }
        }
    }

    .blog-button-wrapper {
        .blog-button {
            background-color: #00EDAC;
            border: none;
            padding: 15px 30px;
            border-radius: 50px;
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            color: #000;
            cursor: pointer;
        }
    }
}