.how-work-section {
    padding: 60px 0 0 0;

    .how-work-alignment {
        max-width: 800px;
        margin: 0 auto;

        .how-work-heading {
            text-align: center;

            h2 {
                font-size: 48px;
                font-weight: 600;
                line-height: 68px;
                color: #000000;
            }
        }

        .how-work-details-alignment {
            padding: 50px 0 0 0;

            .how-word-grid-alignment {
                display: grid;
                grid-template-columns: 60px 1fr;
                gap: 28px;
                padding: 0 0 40px 0;


                .step-details-alignment {
                    .step-box {
                        width: 100%;
                        height: 60px;
                        border-radius: 50%;
                        background: #00edac;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        span {
                            font-size: 32px;
                            line-height: 38px;
                            font-weight: 600;
                            color: #fff;
                        }
                    }

                    p {
                        padding: 12px 0 0 0;
                        font-size: 18px;
                        line-height: 21px;
                        font-weight: 400;
                        color: #000000;
                        text-align: center;
                    }
                }

                .step-right-details-alignment {
                    .box-alignment {
                        width: 100%;
                        border: 1px solid #000000;
                        border-radius: 8px;
                        box-shadow: 0 10px 2px -2px #00edac;
                        padding: 24px;
                        min-height: 100px;

                        @media (max-width: 576px) {
                            width: auto;
                        }

                        h6 {
                            font-size: 24px;
                            line-height: 32px;
                            color: #000;
                            font-weight: 500;
                            padding: 0 0 12px 0;
                        }

                        p {
                            font-size: 14px;
                            line-height: 21px;
                            font-weight: 400;
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}